<template>
  <v-layout v-if="_.size(detail) > 0" row wrap align-space-around justify-center fill-height>
    <v-flex xs12 lg12 xl12>
      <v-card>
        <TitleCard title="Pagos adicionales" subtitle="Listado de costos extras"></TitleCard>
        <v-divider></v-divider>
        <v-data-table :headers="headers" :items="items" class="elevation-0" hide-actions>
          <template slot="items" slot-scope="props">
            <td class="text-xs-left">
              <a :href="props.item.url" target="_blank">
                <span v-html="props.item.message"></span>
              </a>
            </td>
            <td class="text-xs-right">
              {{ props.item.total | toPrice }}
            </td>
            <td class="text-xs-left">
              {{ $moment(props.item.date_expire).format('DD-MM-YYYY hh:mm') }}
            </td>
            <td class="text-xs-left">
              <v-chip v-if="props.item.status" color="success" text-color="white" label style="margin:0;" small>
                <v-icon left>check</v-icon> PAGADO
              </v-chip>
              <v-chip v-else color="error" text-color="white" label style="margin:0;" small>
                <v-icon left>clear</v-icon> PENDIENTE
              </v-chip>
            </td>
            <td class="text-xs-right">
              <v-btn title="Copiar Link" icon @click="copyLink(props.item.url)">
                <v-icon small>link</v-icon>
              </v-btn>
              <v-btn title="Editar Link" icon class="mx-0" @click="openDialog(props.item)">
                <v-icon small>edit</v-icon>
              </v-btn>
              <v-btn title="Eliminar Link" icon flat @click="deleteHandle(props.item.id)">
                <v-icon small>clear</v-icon>
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
    <v-dialog v-model="dialogEdit" persistent max-width="300px">
      <v-card>
        <v-card-title>
          <span class="headline"> Costo extra </span>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12 sm12 md12>
              <v-text-field v-model="total" label="Total" outline clearable></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field v-model="date" type="date" label="Fecha expiracion" outline clearable></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field v-model="time" type="time" label="Hora expiracion" outline clearable></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-textarea
                v-model="message"
                multi-line
                color="secondary"
                :label="`Motivo`"
                outline
                clearable
              ></v-textarea>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-checkbox v-model="notify" color="primary" class="ma-0 pa-0" label="Notificar"> </v-checkbox>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" flat @click.native="dialogEdit = false">Volver</v-btn>
          <v-btn color="primary" flat @click.native="sendDialog">Actualizar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn
      v-if="user.role.id !== 7 && order.histories.findIndex(e => e.status.id === 2) !== -1"
      fixed
      dark
      fab
      bottom
      right
      color="accent"
      @click="openDialog"
    >
      <v-icon> add </v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
import TitleCard from '../useful/titleCard.vue'
import { ORDER } from '../../config'

export default {
  name: 'OrderExtraCost',
  components: { TitleCard },
  props: ['order', 'getOrder'],
  data() {
    return {
      dialogEdit: false,
      items: [],
      detail: {},
      headers: [
        {
          text: 'Comentario',
          align: 'left',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Total',
          align: 'right',
          sortable: false,
          value: 'quantity'
        },
        {
          text: 'Fecha expiracion',
          align: 'left',
          sortable: false,
          value: 'value'
        },
        {
          text: 'Estado',
          align: 'left',
          sortable: false,
          value: 'total'
        },
        { text: '', value: 'actions' }
      ],
      idEdit: '',
      total: '',
      date: '',
      time: '',
      message: '',
      notify: false
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  mounted() {
    this.detail = this.order
    this.$store.dispatch('updateTitle', `Pagos adicionales - Pedido ${this.order.id}`)
    this.get()
  },
  methods: {
    async get() {
      const res = await this.$http.get(`${ORDER}/${this.detail.id}/extras-cost`)
      this.items = res.data
    },
    openDialog(i) {
      if (i) {
        this.idEdit = i.id
        this.total = i.total
        this.date = this.$moment(i.date_expire).format('YYYY-MM-DD')
        this.time = this.$moment(i.date_expire).format('hh:mm')
        this.message = i.message
      }
      this.dialogEdit = true
    },
    async sendDialog() {
      try {
        const params = {
          date: this.date,
          time: this.time,
          total: this.total,
          message: this.message,
          notify: this.notify
        }
        if (this.idEdit) {
          await this.$http.put(`${ORDER}/${this.detail.id}/extras-cost/${this.idEdit}`, params)
        } else {
          await this.$http.post(`${ORDER}/${this.detail.id}/extras-cost`, params)
        }
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Actualizado'
        })
        this.dialogEdit = false
        this.get()
        this.getOrder()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async deleteHandle(id) {
      const result = confirm('Eliminar?')
      if (result) {
        await this.$http.delete(`${ORDER}/${this.detail.id}/extras-cost/${id}`)
        this.get()
      }
    },
    copyLink(url) {
      this.$copyText(url).then(e => {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Copiado con exito!'
        })
      })
    }
  }
}
</script>
